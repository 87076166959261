$primary: #FD6B35;
$highlight: $primary;
$subtle: #7A7A7A;
$light: #dcdcdc;
$text: black;
$background: white;

// https://flatuicolors.com/palette/defo
$tag-colors: (
  ios: #c0392b, // pomegranate red
  design: #f1c40f, // sunflower yellow
  maps: #27ae60, // nephritis green
  web: #1abc9c, // turquoise
  python: #e67e22, // carrot orange
  opensource: #27ae60, // nephritis green
  physics: #e74c3c, // alizarin red
  teaching: #f39c12, // orange
  visualization: #f1c40f, // sunflower yellow
  publications: #2980b9, // belize hole blue
  outreach: #c0392b, // pomegranate red
);
@mixin hover-opacity {
  transition: opacity $transition-duration;
  &:hover, &:active, &:focus {
    opacity: 0.8;
  }
}

$border-radius: 0.25rem;
$opacity-inactive: 0.5;
$transition-duration: 0.2s;
$bg-extend: 0.5em;
$spacer: 2rem;

// Bootstrap variables
// $primary, $light
// $spacer
$font-size-root: 14px;
$body-bg: $background;
$body-color: $text;
$badge-padding-y: .5em;
$badge-padding-x: .7em;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

@import "../bower_components/bootstrap/scss/bootstrap";

// Structure

#site-header {
  margin-top: $spacer;
  margin-bottom: 1.5 * $spacer;

  .name-large {
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(3);
    }
    @include media-breakpoint-down(md) {
      margin-bottom: $spacer;
    }
    color: $primary;
    font-size: 4.5rem;
    font-weight: bold;
    line-height: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tags-navigation {
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(7);
    }
    @include media-breakpoint-up(xs) {
      @include make-col(9);
    }
    font-size: 1.5rem;
  }

  #site-contact {
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(2);
    }
    @include media-breakpoint-up(xs) {
      @include make-col(3);
    }
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    align-content: flex-end;
    .contact {
      margin-left: 1rem;
      color: $light;
      &:hover, &:focus, &:active {
        color: $primary;
      }
      .icon {
        width: 2rem;
        // height: 2rem;
      }
      &.github .icon {
        width: 2.25rem;
      }
    }
  }
}

#site-content {

}

// Sticky Footer
// $footer-height: 60px;
// html {
// 	position: relative;
// 	min-height: 100%;
// }
// body {
// 	margin-bottom: $footer-height + 30px;
// }
// #site-footer {
// 	position: absolute;
// 	bottom: 0;
// 	width: 100%;
// 	height: $footer-height;
// }

#site-footer {
  margin-top: $spacer;
  padding-top: $spacer;
  padding-bottom: $spacer;
  background-color: darken($background, 2%);

  .tags-navigation {
    margin: $spacer 0;
    font-size: 1.2em;
    justify-content: center;
  }

  .contacts {
    @extend .list-group;
    @extend .list-group-horizontal;
    display: flex;
    justify-content: space-between;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }
  .contact {
    @extend .action;
    border-radius: $border-radius;
    margin: 0 $spacer;
    @include media-breakpoint-down(md) {
      margin: 0;
    }
    background-color: $background;
    &:hover, &:focus, &:active {
      background-color: $background !important;
    }
  }
  .contact-supplement {
    @extend .action-supplement;
  }

  .copyright {
    // line-height: $footer-height;
    margin-top: $spacer;
    text-align: center;
    font-size: 0.85em;
  }
}

.container-limited {
  @include media-breakpoint-only(xl) {
    width: map-get($container-max-widths, lg);
  }
}


// Style

h1, h2, h3, h4, h5, h6 {
  a {
    color: inherit;
    text-decoration: none;
    &:hover, &:focus, &:active {
      color: $primary;
      text-decoration: none;
    }
  }
}

h1.page-heading {
  @extend .markdown-decorated;
  @extend .display-2;
  margin-top: 0.8em;
  margin-bottom: 0.6em;
  &.has-tags {
    line-height: 1.5em;
  }
}

p {
	text-align: justify;
  margin-bottom: 1em;
}

a {
  transition: color $transition-duration;
  color: $primary;
  text-decoration: none;
  &:hover, &:focus, &:active {
    color: darken($primary, 10%);
    text-decoration: none;
  }
  img {
    @include hover-opacity;
  }
}

ul {
  margin: 0;
  padding: 0;
}

strong {
	// color: $highlight;
}

.card {
  border: $border-width solid $light;
}

pre {
    background-color: darken($background, 2%);
    padding: 1em;
    margin: 1em;
}


/* Markdown format style */

.markdown-decorated {

  $markdown-format-char: $light;

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1em;
    margin-top: 1.5em;
	  position: relative;
  	&:before {
  		position:absolute;
  		left: -6rem;
  		width: 5rem;
  		text-align: right;
  		color: $markdown-format-char;
  	}
  }
  h1:before {
  	content: '#';
  }
  h2:before {
  	content: '##';
  }
  h3:before {
  	content: '###';
  }

  p {
    strong, em, b, a {
    	&:before, &:after {
        	color: $markdown-format-char;
    	}
    }
    b {
    	&:before, &:after {
        	content: '*';
    	}
    }
    strong {
    	&:before, &:after {
        	content: '*';
    	}
    }
    em {
    	&:before, &:after {
        	content: '/';
    	}
    }
    a {
    	&:before {
        	content: '[';
        }
      &:after {
      	content: ']';
    	}
    }
    .link-target {
      color: $markdown-format-char;
      &:before {
        content: '(';
      }
      &:after {
        content: ')';
      }
    }
  }

  ul {
    padding-left: 2em;
    margin-bottom: 1em;
  }

  blockquote {
    @extend .blockquote;
    border-color: $light;
    font-size: 1.2em;
    color: $subtle;
    p:last-child {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 80%;
    display: block;
    margin: 0 auto;
    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }
    border-radius: $border-radius;
  }
  a img {
    display: inline-block;
    border-radius: 0;
  }
}


// Tags

.tags {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
ul.tags {
  list-style: none;
}

.tag {
  @extend .badge;
  margin: 0.5em;
  h1 & {
    margin: 0;
    padding: 0.25em 0.4em;
  }
  transition: color,background-color,border-color $transition-duration;
  a, a:hover, a:active, a:focus {
    transition-property: none;
    color: inherit;
    text-decoration: none;
  }
  @mixin colorize($slug, $color) {
    &.#{$slug}.inactive {
      background-color: inherit;
      color: $color;
      border: $border-width solid $color;
    }
    &.#{$slug}:not(.inactive), &.#{$slug}.inactive:hover {
      background-color: $color;
      color: $background;
      border: $border-width solid $color;
    }
    &.#{$slug}:not(.inactive):hover {
      background-color: darken($color, 5%);
      border-color: darken($color, 5%);
    }
  }
  @each $tag, $color in $tag-colors {
    @include colorize($tag, $color);
  }
  // &:before {
  //     content: '#\a0';
  // }
  // &:before, &:after {
  //   opacity: 1;
  // }
}


// Profile

.site-intro {
  display: flex;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    .profile-picture {
      margin: 1rem 0;
    }
  }
  align-items: center;
  width: 80%;
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
  margin: 1rem auto;

  .profile-picture {
    margin-right: 2rem;
  }
}

.profile {
  background-color: darken($background, 2%);
  border-radius: $border-radius;
  padding: 1em;
  color: $subtle;
  @extend .markdown-decorated;
}

.profile-picture {
  min-width: 8rem;
  max-width: 8rem;
  min-height: 8rem;
  max-height: 8rem;
  border-radius: 4rem;
  object-fit: cover;
  object-position: 50% 15%;
}


// Projects & publications

.project-list, .publication-list {
  @include make-row();
  .project-item-wrapper {
    @include make-col-ready();
  @include media-breakpoint-up(lg) {
      @include make-col(6);
    }
    @include media-breakpoint-down(lg) {
      @include make-col(12);
  }
    margin-bottom: $spacer;
  }
}

.project-item, .publication-item {
  @extend .card;

  .project-banner img {
    @extend .card-img-top;
    @extend .img-fluid;
  }

  .content {
    @extend .card-body;
  }

  h1, h2, h3, h4, h5, h6 {
    @extend .card-title;
    .app-icon {
        float: right;
    }
  }

  p {
    text-align: left;
  }

  .tags {
    @extend .card-body;
    justify-content: flex-end;
  }

  p:last-child {
    margin-bottom: 0;
  }

}

.publication-item {
  &:not(.publication-highlighted) {
    .content {
      padding-top: 0;
      padding-bottom: 0;
    }
    border: none;
  }
}

.layout-project {

  .project-banner {
    margin-top: $spacer;
    margin-bottom: $spacer;
    text-align: center;
    img {
      max-width: 80%;
      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }
      max-height: 30rem;
      border-radius: $border-radius;
    }
  }

  .tags {
    justify-content: flex-end;
    font-size: 1.2em;
  }

  .actions {
    @extend .actions-block;
  }

}

.subtitle {
	color: $subtle;
	margin-top: 1em;
	margin-bottom: 1em;
	font-size: 1.2em;
	b {
    	// color: $highlight;
	}
  a {
    color: inherit;
    &:hover, &:focus, &:active {
      color: $primary;
    }
  }
}

.actions {
  @extend .list-group;
  @extend .list-group-flush;
  border-top: none !important;
  border-bottom: none !important;
  &.actions-block {
    width: 80%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
    margin: $spacer auto;
    .action {
      border-radius: $border-radius;
    }
  }
}
.action {
  @extend .list-group-item;
  @extend .list-group-item-action;
  transition: background-color $transition-duration;
  color: $primary;
  white-space: nowrap;
  border: none;
  display: flex;
  justify-content: space-between;
  &:hover, &:focus, &:active {
    background-color: darken($background, 2%);
    color: darken($primary, 10%);
    .action-supplement, .action-supplement:after {
      color: darken($light, 10%);
    }
  }
  .action-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .action-supplement {
    // @extend .float-xs-right;
    transition: color $transition-duration;
    color: $light;
    margin-left: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 2em;
    position: relative;
  }
  .action-supplement:after {
    // @extend .float-xs-right;
    color: $light;
    content: "〉";
    // margin-left: 0.6em;
    position: absolute;
    right: 0;
    text-align: right;
    width: 1.5em;
    height: 1.5em;
  }
  @mixin action-symbol($key, $url) {
    &.#{$key} .action-supplement:after {
      content: "";
      background-image: url($url);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      // vertical-align: middle;
    }
  }
  @include action-symbol(github, "/assets/icon_github_light.svg");
  @include action-symbol(pdf, "/assets/icon_pdf.svg");
  @include action-symbol(email, "/assets/icon_email_light.svg");
}

.remote-content-intro {
  color: $subtle;
  text-align: center;
}


// CV

.cv-intro {
  @extend .row;
  .profile-picture-container {
    @extend .order-md-1;
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(2);
    }
    @include media-breakpoint-up(xs) {
      @include make-col(4);
    }
  }
  .profile-container {
    @extend .order-md-2;
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(7);
    }
  }
  .contact-info {
    @extend .order-md-3;
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(3);
    }
    @include media-breakpoint-up(xs) {
      @include make-col(8);
    }
    @include media-breakpoint-down(md) {
      margin-top: $spacer;
    }
    color: $subtle;
    p {
      text-align: right;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.profile-picture-container {
      align-items: center;
    }
  }

}

.cv-content {
  column-count: 1;
  @include media-breakpoint-up(xl) {
    column-count: 2;
  }
  column-gap: 2 * $spacer;
  margin-top: $spacer;
  column-rule: $border-width solid $light;
}

.cv-section {

  .cv-section-title, h2.bibliography {
    color: $primary;
    margin-top: 1.5rem;
    margin-bottom: 1.2rem;
    font-size: 1.2em;
    font-weight: bold;
  }

  .cv-item {
    margin-bottom: 1rem;
    display: flex;

    .cv-item-context {
      color: $subtle;
      min-width: 8em;
      max-width: 8em;
      margin-right: $spacer;
    }

    .cv-item-title {
      font-size: 1em;
      color: $subtle;
    }

    p, ul {
      margin: 0;
    }

    .cv-item-link {
      margin-top: 0rem;
      display: block;
      &::before {
        content: "> ";
      }
    }

  }

}
